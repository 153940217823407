import {gql} from '@apollo/client';

export const CommonMutations = {
    gql: {
        resolveFormInfo: gql`
            mutation ResolveFormInfoMutation($input: ZadostSopWebFormResolveInput!) {
                sopWeb {
                    resolveFormInfo(input: $input) {
                        expectedFormStepPath
                        formType
                    }
                }
            }
        `,
        geoportalCreate: gql`
            mutation GeoportalCreate($parseId: MongoObjectId!) {
                sopWeb {
                    geoPortalBlokCreate(parseId: $parseId) {
                        parseId
                        TID
                        completed
                        id
                        redirectUrl
                    }
                }
            }
        `,
        geoportalUpdate: gql`
            mutation GeoportalUpdate($parseId: MongoObjectId!) {
                sopWeb {
                    geoPortalBlokUpdate(parseId: $parseId) {
                        parseId
                        TID
                        completed
                        id
                        redirectUrl
                    }
                }
            }
        `,
        geoportalComplete: gql`
            mutation GeoportalComplete($TID: String!) {
                sopWeb {
                    geoPortalBlokComplete(TID: $TID) {
                        parseId
                        TID
                        completed
                        id
                        redirectUrl
                    }
                }
            }
        `,
        updatePrilohy: gql`
            mutation UpdatePrilohy($input: ZadostSopWebPrilohyUpdateInput!) {
                sopWeb {
                    updateBlokPrilohy(input: $input) {
                        id
                        archivovan
                        typ
                        velikost
                        nazev
                        mime
                    }
                }
            }
        `,
    },
};
